@layer legacyResetRevert {
	:root {
		-moz-text-size-adjust: 100%;
		-webkit-text-size-adjust: 100%;
		text-size-adjust: 100%;
	}

	body {
		margin: 0;
	}

	:where(.legacy) ol {
		counter-reset: orderedList;
	}

	:where(.legacy) li {
		counter-increment: orderedList;
	}

	:where(.legacy) a,
	:where(.legacy) button,
	:where(.legacy) input[type='submit'] {
		cursor: pointer;
	}
}
