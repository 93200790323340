@layer legacyResetRevert {
	/*
 * Incase we do want default browser styles for some elements
 * This is not copy pasted from The New CSS Reset so it is in
 * a separate global style component
 * Note: this not a place for global styling in general,
 * only for reverting CSS resets
 */

	:where(.legacy) * {
		outline: revert;
		cursor: revert;
	}
	:where(.legacy) {
		font-variant-numeric: oldstyle-nums;
	}
	:where(.legacy) input,
	:where(.legacy) textarea,
	:where(.legacy) select {
		background-color: revert;
	}
	/* :where is used to ensure 0 CSS specificity */
	:where(.legacy) :where(input:where([type='checkbox'], [type='radio'])) {
		all: revert;
		margin: unset;
	}
	:where(.legacy) button:disabled {
		cursor: revert;
	}
	:where(.legacy) [hidden] {
		display: none;
	}
	:where(.legacy) strong {
		font-weight: revert;
	}
	:where(.legacy) em {
		font-style: revert;
	}

	:where(.legacy) dialog {
		all: revert;
	}
}
